import { LOGIN, LOGOUT } from './credentials-types';

const initialState = {
	token: undefined,
	userId: undefined,
	name: '',
};

const credentialsReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return { ...state, ...action.credentials };
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default credentialsReducer;
