/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';

import { setCredentials } from '../../redux/credentials/credentials-actions';
import { verifyToken } from '../../api/login';
import Login from './login';

import styles from './prive.scss';

const cookies = new Cookies();

const Prive = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const verifyCookie = async (token, userId, name) => {
			const response = await verifyToken(token);
			if (response.valid) dispatch(setCredentials({ userId, token, name }));
		};
		if (cookies.get('credentials')) {
			const { token, userId, name } = cookies.get('credentials');
			verifyCookie(token, userId, name);
		}
	}, [dispatch]);
	const { token, name } = useSelector((state) => state.credentials);

	return !token ? (
		<Login />
	) : (
		<div className={styles.container}>
			<h1 className={styles.mainHeader}>
				Welkom {`${name.charAt(0).toUpperCase() + name.slice(1)}`}!
			</h1>
			<h2 className={styles.header}>Huisreglement</h2>
			<p className={styles.mainText}>
				Het grote Los Chichis huisreglement, bediscussieerbaar het meest belangrijke document in
				omloop, is{' '}
				<a
					target="_blank"
					rel="noopener noreferrer"
					className={styles.link}
					href={`${process.env.PUBLIC_URL}/data/Huisreglement-LosChiChis.pdf`}
				>
					hier
				</a>{' '}
				te vinden.
			</p>
			<h2 className={styles.header}>Inkooplijst</h2>
			<p className={styles.mainText}>
				De inkooplijst voor de lieve mensen van de inkoopcommissie is{' '}
				<a
					target="_blank"
					rel="noopener noreferrer"
					className={styles.link}
					href={`${process.env.PUBLIC_URL}/data/inkooplijst.pdf`}
				>
					hier
				</a>{' '}
				te vinden.
			</p>
		</div>
	);
};

export default Prive;
