import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { sendMail } from '../../api/mail';

import styles from './contact.scss';

const validEmailRegex = RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const validators = {
	email: (email) => validEmailRegex.test(email),
	name: (name) => name !== '',
	message: (message) => message !== '',
};

const studyOptions = [
	'Advanced Technology',
	'ATLAS',
	'Biomedische Technologie',
	'Business & IT',
	'Civiele Techniek',
	'Communicatiewetenschappen',
	'Creative Technology',
	'Electrical Engineering',
	'European Public Administration',
	'Gezondheidswetenschappen',
	'Industrieel Ontwerpen',
	'International Business Administration',
	'Psychologie',
	'Scheikundige Technologie',
	'Technische Bedrijfskunde',
	'Technische Geneeskunde',
	'Technische Informatica',
	'Technische Natuurkunde',
	'Technische Wiskunde',
	'Werktuigbouwkunde',
];

const subjectOptions = [
	'Kamerzoeker',
	'Informatie over feesten',
	'Vragen over batavierenrace',
	'Pers- of interviewaanvraag',
	'Huwelijksaanzoek',
	'Overige',
];

const initialData = {
	name: '',
	email: '',
	phone: '',
	facebook: '',
	instagram: '',
	message: '',
	study: '',
	subject: '',
};

const Contact = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(initialData);

	const [errors, setErrors] = useState({
		email: undefined,
		phone: undefined,
		name: undefined,
		facebook: undefined,
		instagram: undefined,
		message: undefined,
	});

	const adjustValue = (event) => {
		event.preventDefault();
		const { name, value } = event.target;

		if (validators[name] && !validators[name](value)) {
			setErrors({ ...errors, [name]: true });
		} else {
			setErrors({ ...errors, [name]: false });
		}

		setData({
			...data,
			[name]: value,
		});
	};

	const validateInputs = () =>
		validators.email(data.email) && validators.name(data.name) && validators.message(data.message);

	const sendEmail = (e) => {
		setIsLoading(true);
		e.preventDefault();
		sendMail(data).then((r) => {
			if (r.success) {
				toast.success('Bericht verstuurd!');
				setData(initialData);
			} else {
				toast.error('Oops! Er ging iets mis. Probeer het anders via kamernet.');
			}
			setIsLoading(false);
		});
	};

	return (
		<div className={styles.container}>
			<h1 className={styles.mainHeader}>Contact</h1>
			<p className={styles.mainText}>
				Ben je op zoek naar een kamer in een van de actiefste studentenhuizen op de campus? Twijfel
				niet langer en vul het onderstaande contactformulier in! We zullen dan zo snel mogelijk
				contact met je opnemen voor een kamerzoekgesprek op een moment dat voor ons en jou het beste
				uitkomt. Tijdens dit gesprek kun je kennis met ons maken en zien wij of je alles in huis
				hebt om een echte ChiChi te worden!
			</p>
			<div className={styles.allInputs}>
				<div className={styles.inputs}>
					<label className={styles.labelRequired} htmlFor="name">
						Naam *
					</label>
					<input
						className={`${styles.input} ${errors.name && styles.errorInput}`}
						placeholder="Naam"
						name="name"
						id="name"
						value={data.name}
						onChange={(e) => adjustValue(e)}
					/>
					<label className={styles.labelRequired} htmlFor="email">
						Email *
					</label>
					<input
						className={`${styles.input} ${errors.email && styles.errorInput}`}
						placeholder="Email"
						id="email"
						value={data.email}
						name="email"
						onChange={(e) => adjustValue(e)}
					/>
					<label className={styles.label} htmlFor="phone">
						Telefoon nummer
					</label>
					<input
						className={styles.input}
						placeholder="+31 6 12345678"
						id="phone"
						value={data.phone}
						name="phone"
						onChange={(e) => adjustValue(e)}
					/>
					<label className={styles.label} htmlFor="facebook">
						Facebook
					</label>
					<input
						className={styles.input}
						placeholder="Facebook"
						id="facebook"
						name="facebook"
						value={data.facebook}
						onChange={(e) => adjustValue(e)}
					/>
					<label className={styles.label} htmlFor="instagram">
						Instagram
					</label>
					<input
						className={styles.input}
						placeholder="Instagram"
						id="instagram"
						name="instagram"
						value={data.instagram}
						onChange={(e) => adjustValue(e)}
					/>
					<label className={styles.label} htmlFor="study">
						Studie
					</label>
					<select
						className={styles.selectInput}
						value={data.study}
						name="study"
						onChange={(e) => adjustValue(e)}
						id="study"
					>
						<option value="" disabled selected>
							Studie...
						</option>
						{studyOptions.map((s) => (
							<option key={s} value={s}>
								{s}
							</option>
						))}
					</select>
					<label className={styles.label} htmlFor="subject">
						Onderwerp
					</label>
					<select
						className={styles.selectInput}
						name="subject"
						value={data.subject}
						id="subject"
						onChange={(e) => adjustValue(e)}
					>
						<option value="" disabled selected>
							Onderwerp...
						</option>
						{subjectOptions.map((s) => (
							<option key={s} value={s}>
								{s}
							</option>
						))}
					</select>
				</div>
				<div className={styles.inputs}>
					<label htmlFor="message" className={styles.labelRequired}>
						Bericht *
					</label>
					<textarea
						onChange={(e) => adjustValue(e)}
						value={data.message}
						name="message"
						className={styles.messageInput}
						id="message"
					></textarea>
				</div>
			</div>
			<button
				onClick={(e) => sendEmail(e)}
				disabled={!validateInputs()}
				className={styles.buttonInput}
			>
				{isLoading ? <div className={styles.loader}></div> : 'Verstuur!'}
			</button>
		</div>
	);
};

export default Contact;
