import React, { useEffect, useState } from 'react';

import styles from './fotos.scss';
import ImageLoad from './image_load';

const Fotos = () => {
	const [photos, setPhotos] = useState([]);

	useEffect(() => {
		const fetchNumPhotos = async () => {
			const result = await fetch(`${process.env.PUBLIC_URL}/data/photos.json`).then((p) =>
				p.json(),
			);

			setPhotos(
				[...Array(result.count).keys()].map(
					(i) => `${process.env.PUBLIC_URL}/data/photos/picture (${i + 1}).jpg`,
				),
			);
		};
		fetchNumPhotos();
	}, []);

	const renderPhotos = () => {
		const photoArrays = [[], [], []];
		const wordsPerLine = Math.ceil(photos.length / 3);
		for (let line = 0; line < 3; line++) {
			for (let i = 0; i < wordsPerLine; i++) {
				photoArrays[line].push(photos[i + line * wordsPerLine]);
			}
		}

		return (
			<div className={styles.photoContainer}>
				{[...Array(3).keys()].map((i) => (
					<div key={i} className={styles.photoColumn}>
						{photoArrays[i].map((p) => (
							<ImageLoad src={p} key={p} />
						))}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			{photos.length > 0 ? renderPhotos() : <div className={styles.loader} />}
		</div>
	);
};

export default Fotos;
