import React from 'react';

import styles from './activiteiten.scss';
import { Link } from 'react-router-dom';

const Activiteiten = () => (
	<div className={styles.container}>
		<h1 className={styles.mainHeader}>Activiteiten</h1>
		<p className={styles.mainText}>
			De Chichis vormen een erg hechte groep. Zo wordt er samen gegeten, of een film gekeken. Ook
			zijn er regelmatig spontaan uitstapjes als even naar de bioscoop of een bowlingbal proberen
			uit de goot te houden onder het genot van een biertje. Daarnaast is er een aantal jaarlijks
			terugkerende evenementen zoals het kerstdiner, het feutenfeest, het flatfeest, het flatuitje
			en de batavierenrace. Verder wordt er zo nu en dan buiten dit alles om een uitje georganiseerd
			naar bijvoorbeeld Bottrop of Duinrell.
		</p>
		<h2 className={styles.header}>Het Kerstdiner</h2>
		<p className={styles.mainText}>
			Elk jaar houden we een kerstdiner. Dit is een exclusief diner bestaande uit meerdere gangen,
			elk verzorgd door enkele bewoners van de flat. Aangezien het een exclusieve aangelegenheid is,
			behoort ook een ieder tijdens deze avond in gala gekleed te zijn. Oh, en: de jongste feuten
			moeten zorgen voor de kerstboom! (deze mag niet gekocht zijn en je mag er ook niet doorheen
			kunnen springen zonder een tak te raken).
		</p>
		<h2 className={styles.header}>Het Flatuitje</h2>
		<p className={styles.mainText}>
			Elk jaar moet er door de jongste feut een uitje van een dag of een weekend worden
			georganiseerd ergens buiten Twente, waarna dit uitje afgesloten wordt met een avond flink
			stappen - en dan 's ochtends weer terug. Zo zijn we al naar Den Haag, Amsterdam, Rotterdam,
			Utrecht, Groningen, Assen, Nijmegen en Scheveningen geweest en hebben we een zeilweekend in
			Sneek gehad.
		</p>
		<h2 className={styles.header}>De Batavierenrace</h2>
		<p className={styles.mainText}>
			Elk jaar is er een batavierenrace. Dit is een estafetteloop van Nijmegen naar Enschede. Wij
			doen hieraan mee en een ieder zal een afstandje moeten rennen varierend van 3 kilometer voor
			de dames, tot over de 10 kilometer voor de heren. Ook voor komende editie zijn we weer op zoek
			naar lopers. Ben je geïnteresseerd?{' '}
			<Link className={styles.link} to="/Contact">
				Mail
			</Link>{' '}
			dan.
		</p>
		<h2 className={styles.header}>Het Feutenfeest</h2>
		<p className={styles.mainText}>
			Elk jaar moet er door de eerstejaars (de feuten) een feest worden georganiseerd. Tijdens dit
			feest kunnen hun vrienden dan kennis maken met Los Chichis en vica versa. Elk jaar levert dit
			weer mooie taferelen en foto's op.
		</p>
		<h2 className={styles.header}>Het Flatfeest</h2>
		<p className={styles.mainText}>
			Het flatfeest wordt georganiseerd door alledrie de flats van het pleintje, en is dus eigenlijk
			een pleinfeest. Naar zo'n feestje komen vaak een man of 700. Er wordt een grote tent op het
			grasveld voor gezet waar samen met de 3 woonkamers en het pleintje het feest zal plaatsvinden.
			Verder wordt er voor een aan het thema gerelateerde sfeer gezorgd. Men kan deze avond voor een
			euro of 12 een pul kopen, die onbeperkt met bier, wijn en fris bijgevuld mag worden uit de
			tapinstallaties. Er speelt een aantal bands en een of meerdere DJ's. Ook is er een
			loungeruimte ingericht waar wat te eten is. Eerder gehouden feesten waren de Chichifeesten,
			het Vijverfeest, Gazafeest, Safarifeest, Partynight in Black &amp; White, Beesten op de
			Hooizolder, Underwater Open-air, Desert Desires, Forbidden City, Time Flies, Area 51,
			Knotsgek, Loaded Barrels en Medical Madness, Labiel in Chernobyl, Enter the Pirate Bay en
			Twente State College. Meer informatie over het afgelopen flatfeest is{' '}
			<a className={styles.link} href="https://flatfeest.loschichis.nl">
				hier
			</a>{' '}
			te vinden.
		</p>
		<h2 className={styles.header}>Overige feestjes</h2>
		<p className={styles.mainText}>
			Ook zijn er meerdere malen per jaar cocktail party's, of bierfesten. Naast deze festiviteiten
			bezoeken de Chichis regelmatig gezamelijk andere feesten, zoals flatfeesten, het UT eindfeest,
			intro-party's en vele andere. Uiteraard als je zin hebt een feestje te geven (omdat je jarig
			bent, iets te vieren hebt, of daar gewoon zin in hebt) dan zullen we dat zeker niet afslaan
			<span role="img" aria-label="smile">
				😀
			</span>
		</p>
	</div>
);

export default Activiteiten;
