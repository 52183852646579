import { sendPostRequest } from './common';

export const sendMail = async (data) => {
	const params = {
		...data,
	};

	const response = await sendPostRequest(params, 'mail/');
	return response;
};
