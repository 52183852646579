import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from './navigation/navigation';
import rootreducer from './redux/rootreducer';
import { HashRouter } from 'react-router-dom';

toast.configure({
	position: toast.POSITION.BOTTOM_RIGHT,
});

const store = createStore(
	rootreducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const App = () => (
	<Provider store={store}>
		<HashRouter>
			<Navigation />
		</HashRouter>
	</Provider>
);

export default App;
