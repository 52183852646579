import React, { useEffect, useState } from 'react';

import styles from './bewoners.scss';
import { Link } from 'react-router-dom';

const kamers = [101, 102, 103, 104, 105, 106, 107, 211, 212, 213, 214, 215, 216, 217];

const renderBewoner = (kamer, bewoner) => {
	const fotoUrl = `${process.env.PUBLIC_URL}/data/bewoners/${kamer}.png`;
	const { Naam } = bewoner;

	return (
		<div className={styles.bewonerCard}>
			<Link
				style={{ textDecoration: 'none' }}
				to={{
					pathname: `/Bewoners/${Naam}`,
					state: {
						bewoner: bewoner,
					},
				}}
			>
				<h1 className={styles.name}>{Naam}</h1>
				<img alt="" src={fotoUrl} className={styles.foto} />
			</Link>
		</div>
	);
};

const Bewoners = () => {
	const [bewoners, setBewoners] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const promises = kamers.map(async (kamer) =>
				fetch(`${process.env.PUBLIC_URL}/data/bewoners/${kamer}.json`).then((r) => r.json()),
			);
			const data = await Promise.all(promises);
			setBewoners(data);
		};
		fetchData();
	}, []);

	return (
		<div className={styles.container}>
			<h1 className={styles.mainHeader}>Bewoners</h1>
			<div className={styles.bewonersContainer}>
				{kamers.map((kamer, index) => bewoners[index] && renderBewoner(kamer, bewoners[index]))}
			</div>
		</div>
	);
};

export default Bewoners;
