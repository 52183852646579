import axios from 'axios';

const apiUrl =
	process.env.NODE_ENV !== 'production'
		? 'http://127.0.0.1/finance/api-finance/'
		: 'https://loschichis.nl/finance/api-finance/';

export const sendPostRequest = (params, endpoint) => {
	const urlparams = new URLSearchParams();
	Object.keys(params).forEach((property) => {
		if (params[property] instanceof Array) {
			Object.keys(params[property][0]).map((k) =>
				urlparams.append(
					property + '_' + k,
					params[property].map((i) => i[k]),
				),
			);
		} else {
			urlparams.append(property, params[property]);
		}
	});

	return axios({
		method: 'post',
		url: apiUrl + endpoint,
		data: urlparams,
		headers: { 'content-type': 'application/x-www-form-urlencoded' },
	})
		.then((response) => response.data)
		.catch((error) => error);
};
