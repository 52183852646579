import React, { useState, useEffect } from 'react';
import { NavLink, Switch, Route, Redirect, useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import Home from '../screens/home/home';
import Fotos from '../screens/fotos/fotos';
import Activiteiten from '../screens/activiteiten/activiteiten';
import Bewoners from '../screens/bewoners/bewoners';
import Contact from '../screens/contact/contact';
import BewonerDetails from '../screens/bewoners/bewoner-details';
import Beerpong from '../screens/beerpong/beerpong';

import styles from './navigation.scss';
import Prive from '../screens/prive/prive';

const pages = ["'t Huis", 'Activiteiten', 'Bewoners', "Foto's", 'Beerpong', 'Contact', 'Prive'];
const pageToComponent = {
	"'t Huis": Home,
	Activiteiten: Activiteiten,
	Bewoners: Bewoners,
	"Foto's": Fotos,
	Beerpong: Beerpong,
	Contact: Contact,
	Prive: Prive,
};

const Navigation = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [popup, setPopup] = useState({ show: false, alleenVrouw: false });
	const [currentLocation, setCurrentLocation] = useState({});

	useEffect(() => {
		const fetchKamerzoekend = async () => {
			const result = await fetch(`${process.env.PUBLIC_URL}/data/kamerzoekend.json`).then((r) =>
				r.json(),
			);
			setPopup(result);
		};
		fetchKamerzoekend();
	}, []);

	let location = useLocation();
	useEffect(() => {
		if (location.pathname !== currentLocation.pathname) {
			setCurrentLocation(location.pathname);
			document.getElementById('content').scrollTo(0, 0);
		}
	}, [currentLocation.pathname, location]);

	return (
		<React.Fragment>
			{popup.show && (
				<div onClick={() => setPopup({ show: false })} className={styles.popup}>
					<div className={styles.popupCard}>
						<h1 className={styles.mainHeader}>Word jij de nieuwe ChiChi?!</h1>
						<FontAwesomeIcon onClick={() => setPopup({ show: false })} icon={faTimes} />
						<img
							src={`${process.env.PUBLIC_URL}/data/images/kamerzoeker${
								popup.alleenVrouw ? '-vrouw.png' : '.png'
							}`}
							alt=""
						/>
						<p className={styles.mainText}>
							We zijn op zoek naar een nieuwe{' '}
							{popup.alleenVrouw && (
								<span style={{ color: 'red', fontWeight: 'bold' }}>vrouwelijke</span>
							)}{' '}
							huisgenoot! Geïntereseerd? Stuur een mail via de{' '}
							<Link to="/contact" className={styles.link}>
								contact
							</Link>{' '}
							pagina
						</p>
					</div>
				</div>
			)}
			<nav className={styles.navbar}>
				<div
					onClick={() => setMenuOpen(false)}
					className={`${styles.mobileOverlay} ${menuOpen || styles.inactive}`}
				/>
				<div className={`${styles.navItems} ${menuOpen || styles.inactive}`}>
					{pages.map((p) => (
						<NavLink
							key={p}
							to={`/${p}`}
							activeClassName={styles.activeNavlink}
							className={styles.navlink}
							onClick={() => setMenuOpen(false)}
						>
							{p}
						</NavLink>
					))}
				</div>
				<FontAwesomeIcon
					onClick={() => setMenuOpen(!menuOpen)}
					icon={faBars}
					className={styles.hamburger}
				/>
			</nav>
			<div id="content" className={styles.content}>
				<Switch>
					<Route path="/Bewoners/:bewoner" component={BewonerDetails} />
					{pages.map((p) => (
						<Route key={p} path={`/${p}`} component={pageToComponent[p]} />
					))}
					<Redirect from="*" to="/'t Huis" />
				</Switch>
				<footer className={styles.footer}>
					<div className={styles.footerLinks}>
						<a href="http://www.loschichis.nl/finance">Finance</a>
						<a href="http://www.loschichis.nl/flatfeest">Flatfeest</a>
					</div>
					<span>Copyright © {new Date().getFullYear()}. All Rights Reserved.</span>
				</footer>
			</div>
		</React.Fragment>
	);
};

export default Navigation;
