import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import { login } from '../../api/login';
import { setCredentials } from '../../redux/credentials/credentials-actions';

import styles from './prive.scss';

const Login = () => {
	const [username, changeUsername] = useState('');
	const [password, changePassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const handleLogin = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		login(username, password).then((response) => {
			if (response.jwt) {
				const { jwt, userId } = response;
				const cookies = new Cookies();
				cookies.set('credentials', { token: jwt, name: username, userId });
				dispatch(setCredentials({ token: jwt, name: username, userId }));
			} else {
				toast.error('Is inloggen nou echt zo moeilijk?');
				setIsLoading(false);
			}
		});
	};

	return (
		<form className={`${styles.form} ${styles.container}`} onSubmit={(e) => handleLogin(e)}>
			<label htmlFor="username">Username</label>
			<input
				id="username"
				type="text"
				onChange={(e) => changeUsername(e.target.value)}
				value={username}
			/>
			<label htmlFor="password">Password</label>
			<input
				id="password"
				type="password"
				onChange={(e) => changePassword(e.target.value)}
				value={password}
			/>

			<button className={styles.loginButton}>
				{isLoading ? <div className={styles.loader}></div> : 'Login!'}
			</button>
		</form>
	);
};

export default Login;
