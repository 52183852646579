import React from 'react';

import styles from './bewoner-details.scss';
import { Redirect } from 'react-router-dom';

const BewonerDetails = ({ location }) => {
	if (!location.state) return <Redirect to="/Bewoners" />;
	const { bewoner } = location.state;
	const foto = `${process.env.PUBLIC_URL}/data/bewoners/${bewoner.Kamer}.png`;

	return (
		<div className={styles.container}>
			<h1 className={styles.mainHeader}>{bewoner.Naam}</h1>
			{Object.keys(bewoner).map((k) =>
				k !== 'Over' ? (
					<div key={k} className={styles.row}>
						<h3 className={styles.detailLabel}>{k}</h3>
						<p className={styles.detailValue}>{bewoner[k]}</p>
					</div>
				) : (
					<div key={k} className={styles.overRow}>
						<h3 className={styles.overLabel}>Over {bewoner.Naam}</h3>
						<p className={styles.overValue}>{bewoner[k]}</p>
					</div>
				),
			)}
			<img className={styles.foto} src={foto} alt="" />
		</div>
	);
};

export default BewonerDetails;
