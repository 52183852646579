import { sendPostRequest } from './common';

export const login = async (username, password) => {
	const params = {
		username,
		password,
	};

	const response = await sendPostRequest(params, 'login/');
	return response;
};

export const verifyToken = async (jwt) => {
	const params = {
		jwt,
	};
	const response = await sendPostRequest(params, 'login/');
	return response;
};
