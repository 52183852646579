import React from 'react';

import styles from './beerpong.scss';

const Beerpong = () => (
	<div className={styles.container}>
		<h1 className={styles.mainHeader}>
			<span role="img" aria-label="Bier!">
				🍺
			</span>{' '}
			Beerpong{' '}
			<span role="img" aria-label="Bier!">
				🍺
			</span>
		</h1>
		<p className={styles.mainText}>
			Jaarlijks organiseren wij de dag voor Oud en Nieuw het Los ChiChis beerpong toernooi! Bij
			gebrek aan duidelijke regels hebben wij zelf het initiatief genomen om een standaard op te
			stellen. De laatste versie van de regels vind je{' '}
			<a
				target="_blank"
				rel="noopener noreferrer"
				className={styles.link}
				href={`${process.env.PUBLIC_URL}/data/Het-grote-Los-Chichis-Beerpongreglement.pdf`}
			>
				hier
			</a>
			.
		</p>
	</div>
);

export default Beerpong;
