import React from 'react';

import styles from './home.scss';
import { Link } from 'react-router-dom';

const Home = () => (
	<div className={styles.container}>
		<img
			src={`${process.env.PUBLIC_URL}/data/images/logo.bmp`}
			alt="Logo"
			className={styles.logo}
		/>
		<hr className={styles.seperator} />
		<p className={styles.mainText}>
			Welkom op de site van studentenhuis Los Chichis. Los Chichis is gevestigd op de campus van
			Unversiteit Twente, een soort bungalowpark, gelegen in een bosrijke omgeving. In het huis is
			het vaak een drukke bedoening. Naast de alledaagse festiviteiten is er ook een aantal
			jaarlijks terugkerende activiteiten dat er door de Chichis georganiseerd wordt. Zo organiseren
			de nieuwe bewoners het feutenfeest en is er voor een ieder die&nbsp;interesse heeft een
			flat-/pleinfeest.
			<br />
			<br />
			Verder genieten de bewoners elk jaar weer van het kerstdiner, en neemt een Los Chichis team
			deel aan de Batavierenrace. Op deze webpagina's is meer informatie te vinden, onder andere
			over{' '}
			<Link className={styles.link} to="/Activiteiten">
				activiteiten
			</Link>{' '}
			en{' '}
			<Link className={styles.link} to="/Bewoners">
				bewoners
			</Link>
			.
		</p>
		<iframe
			src="https://www.google.com/calendar/embed?showTitle=0&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;mode=AGENDA&amp;height=300&amp;wkst=2&amp;hl=nl&amp;bgcolor=%23cc6600&amp;src=cam43%40loschichis.nl&amp;color=%232952A3&amp;ctz=Europe%2FAmsterdam"
			frameBorder="0"
			scrolling="no"
			title="calendar"
			className={styles.calendar}
		></iframe>
		<img
			src={`${process.env.PUBLIC_URL}/data/images/kerstdiner.jpg`}
			alt=""
			className={styles.kerstdinerImage}
		/>
	</div>
);

export default Home;
