import React, { useState, useEffect } from 'react';

import styles from './fotos.scss';

const ImageLoad = React.memo(({ src, alt = '' }) => {
	const placeholder = `${process.env.PUBLIC_URL}/data/images/placeholder.png`;
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [currentSrc, updateSrc] = useState(placeholder);

	useEffect(() => {
		const imageToLoad = new Image();
		imageToLoad.src = src;
		imageToLoad.onload = () => {
			setLoading(false);
			updateSrc(src);
		};
		imageToLoad.onerror = () => {
			setError(true);
		};
	}, [src]);

	return (
		<img
			src={currentSrc}
			style={{
				opacity: loading ? 0.5 : 1,
				transition: 'opacity .15s linear',
				display: error ? 'none' : 'block',
			}}
			className={styles.photo}
			alt={alt}
		/>
	);
});

export default ImageLoad;
